import SpaceIoContext from "./context/SpaceIoContext";
import { AdminHeader, Tooltip } from "freespace-react-components";

const ImageIcon = ({
  imageUrl,
  type,
}: {
  imageUrl: string;
  type: "user" | "account";
}) => {
  const iconType = type === "user" ? "userIcon" : "accountIcon";
  return (
    <>
      <img
        className={`${iconType} w-full rounded-full h-8`}
        src={imageUrl || ""}
        alt="${iconType}"
      />
      <Tooltip
        position={"top"}
        event={"hover"}
        disabled={false}
        target={`.${iconType}`}
      >
        <p className="text-xs p-0.5">
          {type === "user" ? "Profile" : "Account"}
        </p>
      </Tooltip>
    </>
  );
};

export default function Root({
  spaceIOService,
  userService,
  routePathnameTextMapping,
}) {
  const currentUrl = new URL(window.location.href);
  const currentUser = userService.user;
  return (
    <SpaceIoContext.Provider value={spaceIOService}>
      <AdminHeader
        headerText={
          routePathnameTextMapping?.[currentUrl.pathname] || "Freespace"
        }
        isNotification={false}
        userIcon={
          <ImageIcon type={"user"} imageUrl={currentUser?.profileImage || ""} />
        }
        accountIcon={
          <ImageIcon
            type={"account"}
            imageUrl={currentUser?.account?.logoUrl || ""}
          />
        }
      />
    </SpaceIoContext.Provider>
  );
}
